import React, { useEffect, useState } from 'react';
import FeedbackApi from '../../api/feedbackApi';
import { Cookies } from 'react-cookie';
import { Loader } from "../Loader";
import { toast } from "react-toastify";
import { bankAdminRole, capitalizeFirstLetter } from '../../util/constants'

export const AddtoCollaegueModal = ({ selectedList, tableInstance, setAddTocolleagueModal }) => {
  const api = new FeedbackApi();
  const cookies = new Cookies();
  let cookieData = cookies.get('userInfo');
  const [colleagueList, setColleagueList] = useState([]);
  const [selectedColleagues, setSelectedColleagues] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchColleagues = async () => {
      try {
        const res = await api.getAllColleagueList(cookieData._id);
        if (res.success) {
          setColleagueList(res.data);
        }
      } catch (err) {
        console.error('Error fetching colleagues: ', err);
      } finally {
        setLoading(false);
      }
    };

    fetchColleagues();
  }, [cookieData._id]);

  const handleCheckboxChange = (colleagueId) => {
    if (selectedColleagues.includes(colleagueId)) {
      setSelectedColleagues(selectedColleagues.filter(id => id !== colleagueId));
    } else {
      setSelectedColleagues([...selectedColleagues, colleagueId]);
    }
  };

  const handleSaveChanges = () => {
    const selectedRows = tableInstance && tableInstance.getSelectedRowModel().flatRows;

    if (selectedRows.length > 0) {
      let sendObj = {
        userId: cookieData._id,
        feedbackList: selectedList,
        userList: selectedColleagues
      };
      api.assFeedbackToColleague(sendObj).then((res) => {
        if (res.success) {
          toast.success(res.message);
          tableInstance.resetRowSelection();
          setSelectedColleagues([]);
          setAddTocolleagueModal(false);
        } else {
          toast.error(res.message);
          tableInstance.resetRowSelection();
          setSelectedColleagues([]);
          setAddTocolleagueModal(false);
        }
      }).catch((err) => {
        console.error("Add team manager error => ", err);
        tableInstance.resetRowSelection();
        setAddTocolleagueModal(false);
      });
    }
  };

  const resetSelectedColleagues = () => {
    setSelectedColleagues([]);
    tableInstance.resetRowSelection();
    setAddTocolleagueModal(false);
  };

  const groupedColleagues = colleagueList?.reduce((groups, colleague) => {
    if (!groups[colleague.userRole]) {
      groups[colleague.userRole] = {};
    }
    const managerId = colleague.isManager ? colleague._id : colleague.managerId;
    if (!groups[colleague.userRole][managerId]) {
      groups[colleague.userRole][managerId] = {
        manager: colleague.isManager ? colleague : null,
        subordinates: []
      };
    }
    if (!colleague.isManager) {
      groups[colleague.userRole][managerId].subordinates.push(colleague);
    }
    return groups;
  }, {});

  return (
    <div
      className="modal fade deleteModal"
      id="alert-modal1"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              Send to Colleague
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={resetSelectedColleagues}
            >
              ×
            </button>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="modal-body customXscroll">
              {Object.keys(groupedColleagues).length > 0 ? (
                <div className='row'>
                  {Object.keys(groupedColleagues).map((role) => (
                    <div key={role} className='mb-2 col-6' >
                      <h5>{role === 'bankAdmin' ? bankAdminRole : capitalizeFirstLetter(role)}</h5>
                      {Object.keys(groupedColleagues[role]).map((managerId) => {
                        const manager = groupedColleagues[role][managerId].manager;
                        return (
                          <div key={managerId}>
                            {manager && (
                              <div className="form-check d-flex align-items-center">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="colleagueList"
                                  id={`colleague-${manager._id}`}
                                  value={manager._id}
                                  checked={selectedColleagues.includes(manager._id)}
                                  onChange={() => handleCheckboxChange(manager._id)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`colleague-${manager._id}`}
                                >
                                  {capitalizeFirstLetter(manager.name)}
                                </label>
                              </div>
                            )}
                            {groupedColleagues[role][managerId].subordinates.map((colleague) => (
                              <div className={`form-check d-flex align-items-center ${manager ? 'ml-4' : ''}`} key={colleague._id}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="colleagueList"
                                  id={`colleague-${colleague._id}`}
                                  value={colleague._id}
                                  checked={selectedColleagues.includes(colleague._id)}
                                  onChange={() => handleCheckboxChange(colleague._id)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`colleague-${colleague._id}`}
                                >
                                  {capitalizeFirstLetter(colleague.name)}
                                </label>
                              </div>
                            ))}
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </div>
              ) : (
                <p>No colleagues available.</p>
              )}
            </div>
          )}
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={resetSelectedColleagues}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSaveChanges}
              data-dismiss="modal"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


/* import React, { useEffect, useState } from 'react';
import FeedbackApi from '../../api/feedbackApi';
import { Cookies } from 'react-cookie';
import { Loader } from "../Loader";
import { toast } from "react-toastify";


export const AddtoCollaegueModal = ({ selectedList ,tableInstance,setAddTocolleagueModal}) => {
  const api = new FeedbackApi();
  const cookies = new Cookies();
  let cookieData = cookies.get('userInfo');
  const [colleagueList, setColleagueList] = useState([]);
  const [selectedColleagues, setSelectedColleagues] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  

  useEffect(() => {
    // Fetch colleague list from API
    const fetchColleagues = async () => {
      try {

        const res = await api.getColleagueList(cookieData._id);
        if (res.success) {
          setColleagueList(res.data);
        }
      } catch (err) {
        console.error('Error fetching colleagues: ', err);
      }
    };

    fetchColleagues();

  }, []);

  const handleCheckboxChange = (colleagueId) => {
    // Toggle selection of colleague
    if (selectedColleagues.includes(colleagueId)) {
      setSelectedColleagues(selectedColleagues.filter(id => id !== colleagueId));
    } else {
      setSelectedColleagues([...selectedColleagues, colleagueId]);
    }
  };

  const handleSaveChanges = () => {
    // userId, feedbackList, userList
    const selectedRows = tableInstance && tableInstance.getSelectedRowModel().flatRows;userId, feedbackList, userList

    console.log("selectedRows",selectedRows);

    if (selectedRows.length > 0) {
      let sendObj = {
        userId: cookieData._id,
        feedbackList: selectedList,
        userList: selectedColleagues
      }
      api.assFeedbackToColleague(sendObj).then((res) => {
        if (res.success) {
          toast.success(res.message);
  
          // Reset selection to default
          tableInstance.resetRowSelection();
          setSelectedColleagues([]);
          setAddTocolleagueModal(false);
        } else {
          toast.error(res.message);
          // Reset selection to default
          tableInstance.resetRowSelection();
          setSelectedColleagues([]);
          setAddTocolleagueModal(false);
        }
      }).catch((err) => {
        console.error("Add team manager error => ", err);
        // Reset selection to default
        tableInstance.resetRowSelection();
        setAddTocolleagueModal(false);
      });
      // TODO: Add your save logic here, e.g., save to API
      // Reset modal state or close modal as needed
    }
    
  };

  // Function to reset selected colleagues
  const resetSelectedColleagues = () => {
    setSelectedColleagues([]);
    tableInstance.resetRowSelection();
    setAddTocolleagueModal(false);
  };

  return (
    <>
      <div
        className="modal fade deleteModal"
        id="alert-modal1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myLargeModalLabel">
                Send to Colleague
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={resetSelectedColleagues}
              >
                ×
              </button>
            </div>
            {
              colleagueList && colleagueList.length ? (
                <div className="modal-body">
                  {colleagueList.length > 0 ? (
                    <form>
                      {colleagueList.map((colleague) => (
                        <div className="form-check d-flex align-items-center" key={colleague._id}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="colleagueList"
                            id={`colleague-${colleague._id}`}
                            value={colleague._id}
                            checked={selectedColleagues.includes(colleague._id)}
                            onChange={() => handleCheckboxChange(colleague._id)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`colleague-${colleague._id}`}
                          >
                            {colleague.name}
                          </label>
                        </div>
                      ))}
                    </form>
                  ) : (
                    <p>No colleagues available.</p>
                  )}
                </div>
              ) : (
                <Loader />
              )
            }


            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={resetSelectedColleagues}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSaveChanges}
                data-dismiss="modal"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
 */